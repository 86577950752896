<template>
  <nav id="headerNav">
    <div
      class="desktop wrapper hidden lg:grid grid-cols-2 justify-between nav-content"
    >
      <NuxtLink to="/">
        <img src="~/assets/images/logo.png" alt="Scratchpad Studio Logo" />
      </NuxtLink>
      <div class="flex justify-end">
        <ul class="nav-menu">
          <li><NuxtLink class="p-medium" to="/work">Work</NuxtLink></li>
          <li><NuxtLink class="p-medium" to="/about">Studio</NuxtLink></li>
          <li><NuxtLink class="p-medium" to="/contact">Contact</NuxtLink></li>
        </ul>
        <a class="p-medium email" href="mailto:hello@scratchpad.studio"
          >hello@scratchpad.studio</a
        >
      </div>
    </div>
    <div
      class="tablet wrapper nav-content hidden md:flex lg:hidden justify-between gap-6"
    >
      <NuxtLink to="/">
        <img src="~/assets/images/logo.png" alt="Scratchpad Studio Logo" />
      </NuxtLink>
      <ul class="nav-menu">
        <li><NuxtLink class="p-medium" to="/work">Work</NuxtLink></li>
        <li><NuxtLink class="p-medium" to="/about">Studio</NuxtLink></li>
        <li><NuxtLink class="p-medium" to="/contact">Contact</NuxtLink></li>
      </ul>
      <a class="p-medium" href="mailto:hello@scratchpad.studio"
        >hello@scratchpad.studio</a
      >
    </div>
    <div
      class="mobile wrapper nav-content flex md:hidden justify-between w-full"
    >
      <NuxtLink class="flex items-center" to="/">
        <img
          v-if="!mobileMenu"
          src="~/assets/images/pointer-icon.png"
          alt="Scratchpad Studio Logo"
        />
        <img
          v-if="mobileMenu"
          src="~/assets/images/logo-small.png"
          alt="Scratchpad Studio Logo"
        />
      </NuxtLink>
      <button class="p-medium" @click="toggleMobileMenu">
        {{ mobileMenu ? "Close" : "Menu" }}
      </button>
      <div
        v-if="mobileMenu"
        class="mobile-menu justify-center flex-col wrapper"
      >
        <a class="p-medium email" href="mailto:hello@scratchpad.studio"
          >hello@scratchpad.studio</a
        >
        <ul class="nav-menu">
          <li><NuxtLink class="h4" to="/work">Work</NuxtLink></li>
          <li><NuxtLink class="h4" to="/about">Studio</NuxtLink></li>
          <li><NuxtLink class="h4" to="/contact">Contact</NuxtLink></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
const route = useRoute();
const mobileMenu = ref(false);
const toggleMobileMenu = () => {
  mobileMenu.value = !mobileMenu.value;
  toggleOverflow();
};
const toggleOverflow = () => {
  if (process.client) {
    document.body.classList.toggle("overflow-hidden");
  } else {
    useHead({ bodyAttrs: { class: `${mobileMenu ? "overflow-hidden" : ""}` } });
  }
};
watch(
  route,
  (value) => {
    mobileMenu.value = false;
    if (process.client) {
      document.body.classList.remove("overflow-hidden");
    } else {
      useHead({
        bodyAttrs: { class: `${mobileMenu ? "overflow-hidden" : ""}` },
      });
    }
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss">
#headerNav {
  height: $nav-height;
  background-color: $sand;
  position: sticky;
  padding-top: 35px;
  padding-bottom: 35px;
  top: 0;
  z-index: 99;
  @media all and (max-width: $mobile) {
    height: $nav-mobile-height;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
</style>

<style lang="scss" scoped>
.nav-menu {
  list-style: none;
  a:hover,
  a.router-link-active {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
}
.email {
  margin-left: 140px;
  margin-right: 10px;
}
.mobile-menu {
  display: flex;
  background-color: $sand;
  position: absolute;
  top: $nav-height;
  left: 0;
  height: calc(100vh - $nav-height);
  width: 100%;
  .email {
    margin-bottom: 32px;
  }
}
@media all and (max-width: $tablet) {
  .email {
    margin-left: 0;
  }
}
@media all and (max-width: $mobile) {
  .mobile-menu {
    top: $nav-mobile-height;
    height: calc(100vh - $nav-mobile-height);
    .email {
      margin-bottom: 32px;
    }
  }
}
</style>
