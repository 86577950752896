<template>
  <div id="mainContainer">
    <NavBar />
    <div class="content">
      <div class="page">
        <NuxtPage />
      </div>
      <!-- <CustomFooter /> -->
    </div>
  </div>
</template>
<script setup>
import { store } from "~/store";
import { useWindowSize } from "@vueuse/core";
import { watch } from "vue";

const { width, height } = useWindowSize();
const route = useRoute();

watch(width, async (newSize, oldSize) => {
  if (newSize > 768 && store.mobileMenu) {
    store.closeMobileMenu();
  }
});
watch(route, () => {
  if (store.mobileMenu) {
    store.closeMobileMenu();
  }
});
</script>
<style lang="scss">
#mainContainer {
  min-height: 100vh;
  position: relative;
  background-color: $sand;
  > .content {
    min-height: calc(100vh - $nav-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .page {
      flex-grow: 2;
    }
  }
}

@media all and (max-width: $mobile) {
  #mainContainer {
    > .content {
      min-height: calc(100vh - $nav-mobile-height);
    }
  }
}
</style>
