// store.js
import { reactive } from "vue";

export const store = reactive({
  mobileMenu: false,
  toggleMobileMenu() {
    this.mobileMenu = !this.mobileMenu;
  },
  closeMobileMenu(){
    this.mobileMenu = false;
  }
});
